<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <form @submit.prevent="handleLogin">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    placeholder="Email"
                    v-model="form.email"
                    
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="form.password"
                    
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  
                  <CRow>
                    <CCol col="6" class="text-left">
                      <button type="submit" class="btn px-4 btn-primary">
                        Login
                      </button>
                    </CCol>
                    <div><CAlert :show.sync="errors" color="danger">{{
                  $t("error_login")
                }}</CAlert></div>
                    <!--CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Forgot password?</CButton>
                      <CButton color="link" class="d-lg-none">Register now!</CButton>
                    </CCol-->
                  </CRow>
                </form>
              </CCardBody>
            </CCard>
            <CCard
              color="darkgrey"
              text-color="white"
              class="text-center py-5 d-md-down-none col-logo"
              body-wrapper
            >
              <img src="@/assets/logo_bizzscreen.png" />
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: { email: null, password: null },
      errors: 0,
      error_msg: "",
    };
  },
  methods: {
    async handleLogin() {
      try {
        await window.axios.get("/sanctum/csrf-cookie");
        let rep = await window.axios.post("/login", this.form);
        console.log(rep);

        let response = await window.axios.get("/api/user");
        this.$store.commit("setAuth", response.data);

        console.log(response);

        this.$router.push("/dashboard");
        console.log("end");
      } catch (error) {
        this.errors = 1;
        this.error_msg = error.response.data.message;
        console.log(error);
      }
    },
    checkEmail() {
      const mailformat = /\S+@\S+\.\S+/;
      
      return mailformat.test(this.form.email)
    },
  },
};
</script>

<style scoped>
.col-logo,
.bg-darkgrey {
  background-color: #222222 !important;
}
.col-logo img {
  max-width: 180px;
}

.alert-danger{
  padding: 0.4rem;
}
</style>
